import styled from 'styled-components';
import backgroundHero from '~/assets/banners/background-hero.png';

import sunday from '~/assets/defaults/sunday.svg';
import thursday from '~/assets/defaults/thursday.svg';

interface IImg {
  src: string;
}

export const Container = styled.div``;

export const Hero = styled.div`
  min-height: 100vh;
  background-image: url(${backgroundHero});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Warnings = styled.div`
  div.bg-gray {
    background: #202020;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    h2 {
      color: #8f8f8f;
    }

    h3 {
      line-height: 45px;
    }

    .carousel {
      .btn-prev,
      .btn-next {
        background: #696969;
        border: 1px solid #aeaeae;
        border-radius: 12px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition-duration: 0.3s;

        svg {
          fill: #fff;
          width: 18px;
          height: 18px;
          transition-duration: 0.3s;
        }

        :hover {
          background: #474747;
          border: 1px solid #5f5f5f;

          svg {
            fill: #959595;
          }
        }
      }
    }
  }

  div.sunday {
    background-image: url(${sunday});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;

    h3 {
      position: relative;
      line-height: 60px;
      .detail {
        position: absolute;
        top: -10px;
        right: 30px;
      }
    }

    > div {
      border: 2px solid rgba(188, 188, 188, 0.26);
      background: rgba(248, 248, 248, 0.55);
      backdrop-filter: blur(2.5px);
      border-radius: 27px;

      hr {
        opacity: 1;
        background-color: #e1e1e1;
        border: none;
        width: 100%;
        height: 1px;
      }

      a {
        background: #dbdbdb;
        backdrop-filter: blur(26.5px);
      }
    }
  }

  div.thursday {
    background-image: url(${thursday});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;

    h3 {
      position: relative;
      line-height: 60px;
      .circle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
        width: 140%;
      }
    }

    a {
      background: #242424;
      backdrop-filter: blur(26.5px);
    }
  }

  @media screen and (min-width: 991px) {
    div.sunday {
      h3 {
        .detail {
          top: -26px;
          right: -32px;
        }
      }
    }

    div.thursday {
      h3 {
        .circle {
          width: unset;
        }
      }
    }
  }
`;

export const News = styled.div`
  h2 {
    color: #8f8f8f;
  }

  h3 {
    line-height: 45px;
  }
`;

export const Events = styled.div`
  h2 {
    color: #8f8f8f;
  }
  h3 {
    line-height: 45px;
  }
`;

export const Img = styled.div<IImg>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 344px;
  border-radius: 16px;
`;

export const AboutUs = styled.div`
  .simbols {
    > div {
      margin-right: 23px;
      margin-left: 23px;

      > div {
        width: 72.35px;
        height: 72.35px;
        border-radius: 16px;

        + div {
          margin-left: 23px;
        }
      }

      + div {
        margin-top: 23px;
      }
    }

    .cross {
      background-color: #e2171c;
    }

    .dove {
      background-color: #fbe919;
    }

    .cup {
      background-color: #0373a9;
    }

    .crown {
      background-color: #6e2560;
    }
  }

  h2 {
    color: #8f8f8f;
    line-height: 24px;
  }

  h3 {
    line-height: 40px;
  }

  .minister {
    img {
      border-radius: 16px;
      width: 96.5%;
    }
  }

  .know-church {
    width: 100%;
    position: relative;

    p {
      line-height: 26px;
    }

    :before {
      content: '';
      position: absolute;
      background: #ececec;
      border-radius: 22px;
      height: 100%;
      width: 5px;
      left: 0;
    }

    :after {
      content: '';
      position: absolute;
      background: #838383;
      border-radius: 22px;
      height: 216px;
      width: 5px;
      top: 0;
      left: 0;
    }
  }

  .show-more {
    a {
      background: #8f8f8f;
      backdrop-filter: blur(26.5px);
      line-height: 30px;
      letter-spacing: -1.2px;
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  .photos {
    img {
      border-radius: 16px;
      width: 96.5%;
    }
  }

  @media screen and (min-width: 429px) {
    .simbols {
      > div {
        margin: 0;

        + div {
          margin-left: 23px;
          margin-top: 0;
        }
      }
    }

    h2 {
      line-height: 45px;
    }

    h3 {
      line-height: 60px;
    }

    .know-church {
      width: 86%;

      p {
        line-height: 36px;
      }
    }

    .show-more {
      a {
        display: unset;
        width: unset;
      }
    }

    .photos {
      img {
        + img {
          margin-top: 24px;
        }
      }
    }
  }
`;

export const Contact = styled.div`
  .box {
    background: #202020;
    border-radius: 30px;

    h2 {
      color: #dcdcdc;
    }

    a {
      padding: 1px;
      border-radius: 16px;
      transition-duration: 0.3s;
      background: transparent;
      background: linear-gradient(90deg, transparent 0%, transparent 100%);

      > div {
        color: #fff;
        font-weight: 500;
        background: transparent;
        transition-duration: 0.3s;
        background: #202020;
        padding: 24px 16px;
        border-radius: 16px;

        span {
          width: calc(100% - 35px);
          overflow-wrap: break-word;
        }
      }

      :hover {
        background: rgb(219, 25, 30);
        background: linear-gradient(
          90deg,
          rgba(219, 25, 30, 1) 0%,
          rgba(219, 25, 30, 0.5) 40%,
          rgba(3, 115, 169, 0.5) 60%,
          rgba(3, 115, 169, 0.25) 75%,
          rgba(3, 115, 169, 0) 100%
        );

        > div {
          background: linear-gradient(90deg, #352322 0%, #232a30 100%);
        }
      }

      + a {
        margin-top: 24px;
      }
    }

    .sub-box {
      background: #2d2d2d;
      border-radius: 20px;

      label {
        + label {
          margin-top: 32px;
        }
      }

      button {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #e4e4e4;
      }
    }
  }
`;
