import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Container, Menu } from './styles';

import whiteLogo from '~/assets/logos/white-logo.svg';
import logo from '~/assets/logos/logo.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [pageId, setPageId] = useState('inicio');
  const [whiteMenu, setWhiteMenu] = useState(false);

  useEffect(() => {
    setActive(false);
    if (location.hash) {
      setPageId(location.hash.replace('#', ''));
      history.push(process.env.PUBLIC_URL);
    }
  }, [history, location.hash]);

  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(pageId, {
        smooth: 'easeInOutQuart',
        offset: -100,
      });
    }, 100);
    setActive(false);
  }, [pageId]);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      const menu = document.getElementById('menu');
      const hero = document.getElementById('inicio');
      if (menu && hero) {
        const { bottom } = hero.getBoundingClientRect();
        setWhiteMenu(window.scrollY >= bottom + menu.offsetHeight);
      }
    });
  }, []);

  const LinkComponent = useMemo<any>(() => {
    if (location.pathname !== '/') {
      return NavLink;
    }

    return AnchorLink;
  }, [location.pathname]);

  return (
    <>
      <Container active={active} className="menu-header" whiteMenu={whiteMenu}>
        <div
          id="menu"
          className="container-fluid py-3 py-lg-0 w-lg-100 menu-fix d-lg-flex align-items-center"
        >
          <div className="row w-lg-100">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-lg-center text-center">
                    <div>
                      <NavLink to="/">
                        <img
                          src={whiteMenu || active ? logo : whiteLogo}
                          alt="logo"
                        />
                      </NavLink>
                    </div>
                    <Menu active={active} whiteMenu={whiteMenu}>
                      <button
                        type="button"
                        className="bg-transparent d-lg-none border-0 mx-3"
                        onClick={() => setActive(!active)}
                      >
                        <span />
                        <span />
                        <span />
                      </button>
                      <div className="menu-group active">
                        <div className="menu d-flex flex-column flex-lg-row justify-content-center">
                          <LinkComponent
                            href="#inicio"
                            to={`${process.env.PUBLIC_URL}/`}
                            offset={100}
                            className={`btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center ${
                              pageId === 'inicio' ? 'active' : ''
                            }`}
                            onClick={() => setPageId('inicio')}
                            exact
                          >
                            Inicio
                          </LinkComponent>
                          <LinkComponent
                            href="#avisos"
                            to={`${process.env.PUBLIC_URL}/#avisos`}
                            offset={95}
                            className={`btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center ${
                              pageId === 'avisos' ? 'active' : ''
                            }`}
                            onClick={() => setPageId('avisos')}
                            exact
                          >
                            Avisos
                          </LinkComponent>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/noticias`}
                            className="btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                            onClick={() => setPageId('noticias')}
                            exact
                          >
                            Notícias
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/acontecimentos`}
                            className="btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                            onClick={() => setPageId('acontecimentos')}
                            exact
                          >
                            Acontecimentos
                          </NavLink>
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/quem-somos`}
                            className="btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center"
                            activeClassName="active"
                            onClick={() => setPageId('quem-somos')}
                            exact
                          >
                            Quem somos
                          </NavLink>
                          <LinkComponent
                            href="#contato"
                            to={`${process.env.PUBLIC_URL}/#contato`}
                            offset={100}
                            className={`btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center ${
                              pageId === 'contato' ? 'active' : ''
                            }`}
                            onClick={() => setPageId('contato')}
                            exact
                          >
                            Contato
                          </LinkComponent>
                        </div>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
