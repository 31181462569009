import React from 'react';
import { RiFacebookFill, RiInstagramLine } from 'react-icons/ri';
import { SiWhatsapp, SiYoutube } from 'react-icons/si';

import { Container } from './styles';

import logo from '~/assets/logos/white-logo.svg';
import { BsFillEnvelopeFill } from 'react-icons/bs';

const Footer: React.FC = () => {
  return (
    <Container>
      <div className="footer-border">
        <div className="container">
          <div className="row mx-2">
            <div className="col-lg-4 my-5 pt-4">
              <img src={logo} alt="logo" className="d-block mx-auto mx-lg-0" />
            </div>
          </div>
          <div className="row mx-2 pb-4 justify-content-center justify-content-lg-end">
            <div className="col-12 col-lg-3 d-flex align-items-lg-center justify-content-end social-media">
              <a
                href="https://www.youtube.com/@IgrejaQ540"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-footer d-flex justify-content-center align-items-center">
                  <SiYoutube size={24} color="#000" />
                </div>
              </a>
              <a
                href="https://www.instagram.com/q540oficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-footer d-flex justify-content-center align-items-center">
                  <RiInstagramLine size={28} color="#000" />
                </div>
              </a>
              <a
                href="https://www.facebook.com/q540oficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-footer d-flex justify-content-center align-items-center">
                  <RiFacebookFill size={28} color="#000" />
                </div>
              </a>
              <a
                href="mailto:contato@quadrangular540.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon-footer d-flex justify-content-center align-items-center">
                  <BsFillEnvelopeFill size={28} color="#000" />
                </div>
              </a>
            </div>
            <div className="col-12 my-5 px-3 col-lg-10 line-footer w-100 mb-4" />
            <div className="col-12 d-flex flex-column flex-lg-row justify-content-between pb-3 pb-lg-0">
              <small className="text-center text-lg-start text-white">
                © 2023 Todos os direitos reservados a{' '}
                <b>IGREJA DO EVANGELHO QUADRANGULAR.</b>
              </small>
              <small className="text-center text-lg-start mt-3 mt-lg-0 text-white">
                Desenvolvido por:{' '}
                <a
                  href="https://devsigner.com.br/"
                  target="_blank"
                  className="text-white"
                  rel="noreferrer"
                >
                  <b>Devsigner</b>
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
