import { lighten } from 'polished';
import styled from 'styled-components';

interface IItem {
  active?: boolean;
}

export const Container = styled.div`
  .btn-prev,
  .btn-next {
    width: 38px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid #292929;
    background: transparent;
    transition-duration: 0.3s;

    svg {
      fill: #292929;
    }

    :hover {
      background: #292929;

      svg {
        fill: #ffffff;
      }
    }
  }

  button[disabled] {
    border: 1px solid ${lighten(0.5, '#292929')};

    svg {
      fill: ${lighten(0.5, '#292929')};
    }

    :hover {
      background: transparent;
    }
  }
`;

export const SliderComponent = styled.div``;

export const Item = styled.button<IItem>`
  width: 100%;
  height: 365px;
  background: #1976d2;
  background: linear-gradient(180deg, #1976d2 16.15%, #2d2d2d 100%);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
  position: relative;
  transition-duration: 0.3s;
  border: none;

  h4,
  p {
    color: #fff;
  }

  h4 {
    transition-duration: 0.3s;
    font-size: ${(props) => (props.active ? 48 : 24)}px;
    transform: rotateZ(${(props) => (props.active ? 0 : -90)}deg);
    width: ${(props) => (props.active ? 'unset' : 50)}px;
  }

  p {
    transition-duration: 0.3s;
    opacity: ${(props) => (props.active ? 1 : 0)};
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
    height: ${(props) => (props.active ? 24 : 0)}px;
  }

  + button {
    margin-left: 40px;
  }

  @media screen and (min-width: 992px) {
    width: ${(props) => (props.active ? 500 : 140)}px;
  }
`;

export const ButtonLeft = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #292929;
  background: transparent;
  transition-duration: 0.3s;
  bottom: -70px;
  top: unset;
  left: 35%;
  transform: translateY(-50%);

  svg {
    fill: #292929;
  }

  :hover {
    background: #292929;

    svg {
      fill: #ffffff;
    }
  }

  :before,
  :after {
    display: none;
  }

  @media screen and (min-width: 576px) {
    left: 41%;
  }

  @media screen and (min-width: 768px) {
    left: 43%;
  }
`;

export const ButtonRight = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #292929;
  background: transparent;
  transition-duration: 0.3s;
  bottom: -70px;
  top: unset;
  right: 35%;
  transform: translateY(-50%);

  svg {
    fill: #292929;
  }

  :hover {
    background: #292929;

    svg {
      fill: #ffffff;
    }
  }

  :before,
  :after {
    display: none;
  }

  @media screen and (min-width: 576px) {
    right: 41%;
  }

  @media screen and (min-width: 768px) {
    right: 43%;
  }
`;
