import styled from 'styled-components';

export const Container = styled.footer`
  .footer-border {
    background: #202020;

    h5 {
      font-size: 18px;
      color: #ffffff;
    }
  }

  .social-media {
    a {
      .icon-footer {
        border-radius: 50%;
        width: 56px;
        height: 56px;
      }

      :nth-child(1) {
        .icon-footer {
          background-color: #e2171c;
        }
      }

      :nth-child(2) {
        .icon-footer {
          background-color: #fbe919;
        }
      }

      :nth-child(3) {
        .icon-footer {
          background-color: #0373a9;
        }
      }

      :nth-child(4) {
        .icon-footer {
          background-color: #6e2560;
        }
      }

      + a {
        margin-left: 16px;
      }
    }
  }

  .icon-whatsapp-call {
    background: #22de57;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    position: fixed;
    bottom: 100px;
    right: 20px;
  }

  .line-footer {
    border: 1px solid #464646;
  }

  @media screen and (min-width: 992px) {
    .icon-footer {
      width: 56px;
      height: 56px;
      svg {
        font-size: 28px;
      }
    }

    .icon-whatsapp-call {
      bottom: 10px;
      right: 100px;
    }
  }
`;
