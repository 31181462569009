import React from 'react';
import { CgMouse } from 'react-icons/cg';
import { IoNotificationsSharp } from 'react-icons/io5';
import { CiStreamOn } from 'react-icons/ci';
import {
  BsFillEnvelopeFill,
  BsFillTelephoneFill,
  BsSunFill,
} from 'react-icons/bs';
import { RiMoonFill } from 'react-icons/ri';
import { BiNews } from 'react-icons/bi';
import { MdGroups, MdOutlineUpdate } from 'react-icons/md';
import { TbMapPinFilled } from 'react-icons/tb';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

import {
  Container,
  Hero,
  Warnings,
  News,
  Events,
  Img,
  AboutUs,
  Contact,
} from './styles';
import Carousel from '~/components/Carousel';

import logo from '~/assets/logos/white-logo.svg';
import news from '~/assets/defaults/news.png';
import detail from '~/assets/icons/detail.svg';
import circle from '~/assets/icons/circle.svg';
import events from '~/assets/defaults/events.png';
import cross from '~/assets/icons/cross.svg';
import dove from '~/assets/icons/dove.svg';
import cup from '~/assets/icons/cup.svg';
import crown from '~/assets/icons/crown.svg';
import minister from '~/assets/defaults/minister.png';
import church from '~/assets/defaults/church.png';
import { Form } from '@unform/web';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';

const Home: React.FC = () => {
  return (
    <Container>
      <Hero id="inicio" className="d-flex align-items-end pb-5">
        <div className="container pb-5">
          <div className="row align-items-end justify-content-center">
            <div className="col-lg-6">
              <h1 className="h2 h1-lg fw-semibold d-flex flex-column align-items-center text-white">
                <span className="mb-2 text-center">
                  Quadrangular{' '}
                  <span className="d-none d-lg-inline-block">|</span>{' '}
                  <img src={logo} alt="logo" className="my-3 my-lg-0" />{' '}
                </span>
                <span className="fw-light h3 text-center">
                  Uma igreja que se preocupa com você
                </span>
              </h1>
              <div className="d-flex flex-column align-items-center mt-4">
                <p className="fw-medium h5 text-white">Ver mais</p>
                <CgMouse size={34} color="#fff" />
              </div>
            </div>
          </div>
        </div>
      </Hero>
      <Warnings id="avisos">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 mb-4">
              <div className="bg-gray py-5 px-2 px-lg-5">
                <div className="w-lg-25 mx-auto mb-5">
                  <h2 className="fw-normal text-center h3">
                    <IoNotificationsSharp size={30} color="#8F8F8f" /> Avisos
                  </h2>
                  <h3 className="text-center text-white fw-semibold">
                    Fique por dentro das nossas novidades
                  </h3>
                </div>
                <Carousel className="carousel">
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                </Carousel>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sunday d-flex flex-column align-items-center py-5 px-2 px-lg-5">
                <h3 className="h2 fw-normal text-center">
                  Cultos de <b>Domingo</b>
                  <img src={detail} alt="Detail" className="detail" />
                </h3>
                <div className="w-lg-75 p-4 mt-3">
                  <p className="h5 h3-lg fw-light px-lg-4 mb-0 d-flex justify-content-between align-items-center">
                    <span>
                      <BsSunFill size={24} color="#6E2560" className="me-3" />
                      Manhã
                    </span>{' '}
                    <b className="ms-3 h5 h4-lg fw-semibold mb-0">09h às 11h</b>
                  </p>
                  <hr />
                  <p className="h5 h3-lg fw-light px-lg-4 mb-0 d-flex justify-content-between align-items-center">
                    <span>
                      <RiMoonFill size={24} color="#6E2560" className="me-3" />{' '}
                      Noite
                    </span>{' '}
                    <b className="ms-3 h5 h4-lg fw-semibold mb-0">18h às 20h</b>
                  </p>
                  <a
                    href="/#"
                    className="d-block mx-auto rounded-pill w-100 w-lg-75 text-center py-2 mt-4"
                  >
                    <CiStreamOn size={33} color="#000000" /> Assistir on-line
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <div className="thursday d-flex flex-column align-items-center py-5 px-2 px-lg-5 h-100">
                <h3 className="h2 text-white fw-normal text-center">
                  Culto <b className="d-block">Quinta-feira</b>
                  <img src={circle} alt="Detail" className="circle" />
                </h3>
                <div className="w-lg-75 p-4 mt-3">
                  <p className="h5 h3-lg fw-light px-lg-4 mb-0 d-flex justify-content-between align-items-center  text-white pb-2">
                    <span>
                      <RiMoonFill size={24} color="#fff" className="me-3" />{' '}
                      Noite
                    </span>{' '}
                    <b className="ms-3 h5 h4-lg fw-semibold mb-0">19h30</b>
                  </p>
                  <a
                    href="/#"
                    className="d-block mx-auto rounded-pill w-100 w-lg-75 text-center py-2 mt-4 text-white"
                  >
                    <CiStreamOn size={33} color="#fff" /> Assistir on-line
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Warnings>
      <News>
        <div className="container">
          <div className="row pb-lg-5 pt-lg-5">
            <div className="col-12 mb-4">
              <div className="py-5 px-2 px-lg-5">
                <div className="w-lg-25 mx-auto mb-5">
                  <h2 className="fw-normal text-center h3">
                    <BiNews size={30} color="#8F8F8f" /> Notícias
                  </h2>
                  <h3 className="text-center fw-semibold">
                    Fique por dentro das nossas novidades
                  </h3>
                </div>
                <Carousel className="carousel">
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                  <img src={news} alt="news" />
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </News>
      <Events>
        <div className="container">
          <div className="row pb-lg-5 pt-lg-5">
            <div className="col-12 mb-4">
              <div className="py-5 px-2 px-lg-5">
                <div className="w-lg-50 mx-auto mb-5">
                  <h2 className="fw-normal text-center h5 h3-lg">
                    <MdOutlineUpdate size={30} color="#8F8F8f" /> Acontecimentos
                  </h2>
                  <h3 className="text-center fw-semibold">
                    O que aconteceu na Q540
                  </h3>
                </div>
                <div className="d-none d-lg-block">
                  <div className="row">
                    <div className="col-lg-6">
                      <Img src={events} />
                    </div>
                    <div className="col-lg-3">
                      <Img src={events} />
                    </div>
                    <div className="col-lg-3">
                      <Img src={events} />
                    </div>
                    <div className="col-lg-5 mt-4">
                      <Img src={events} />
                    </div>
                    <div className="col-lg-7 mt-4">
                      <Img src={events} />
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none">
                  <Carousel className="carousel">
                    <div className="px-1">
                      <Img src={events} />
                    </div>
                    <div className="px-1">
                      <Img src={events} />
                    </div>
                    <div className="px-1">
                      <Img src={events} />
                    </div>
                    <div className="px-1">
                      <Img src={events} />
                    </div>
                    <div className="px-1">
                      <Img src={events} />
                    </div>
                    <div className="px-1">
                      <Img src={events} />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Events>
      <AboutUs>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-8 mb-5 mb-lg-0">
              <div className="d-flex flex-wrap justify-content-center justify-content-sm-start simbols mb-5">
                <div className="d-flex">
                  <div className="cross d-flex align-items-center justify-content-center">
                    <img src={cross} alt="cross" />
                  </div>
                  <div className="dove d-flex align-items-center justify-content-center">
                    <img src={dove} alt="dove" />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="cup d-flex align-items-center justify-content-center">
                    <img src={cup} alt="cup" />
                  </div>
                  <div className="crown d-flex align-items-center justify-content-center">
                    <img src={crown} alt="crown" />
                  </div>
                </div>
              </div>
              <h2 className="h4 h3-lg fw-normal d-flex align-items-center">
                <MdGroups size={30} color="#8F8F8F" className="me-2" />
                Quem somos
              </h2>
              <h3 className="h3 h1-lg fw-semibold">Conheça a nossa igreja</h3>
              <div className="d-flex justify-content-center d-lg-none minister mt-5">
                <img src={minister} alt="minister" />
              </div>
              <div className="know-church px-4 mt-5 mt-lg-0">
                <p className="fw-light h6 h4-lg mb-4">
                  Aimée Semple McPherson, uma evangelista conhecida como "Irmã
                  Aimee", fundou a Igreja do Evangelho Quadrangular em 1° de
                  janeiro de 1923, em Los Angeles, Califórnia, com a inauguração
                  da sede internacional Angelus Temple, que tinha capacidade
                  para cinco mil pessoas.
                </p>
                <p className="fw-light h6 h4-lg mb-4">
                  Nos primeiros meses, sete mil pessoas se converteram a Jesus.
                  Trinta dias depois, foi inaugurado o Instituto de Treinamento
                  Evangelístico e Missionário e uma sala de oração, consagrada e
                  tendo como base o versículo “orai sem cessar”.
                </p>
                <p className="fw-light h6 h4-lg mb-4">
                  Aimée dirigia 21 cultos por semana, participava de eventos
                  públicos e parava completamente as ruas de Los Angeles,
                  diretamente para o Angelus Temple.
                </p>
                <p className="fw-light h6 h4-lg mb-4">
                  Quando irmã Aimée concluiu seu ministério, em 1944, a
                  presidência do movimento Quadrangular e da Cruzada
                  Internacional de Evangelização passou para seu filho Rolf K.
                  McPherson, que serviu ao corpo diretivo por 44 anos. A mudança
                  da liderança não desacelerou o progresso. Ao contrário. Sob
                  sua responsabilidade, o movimento passou de 400 igrejas para
                  mais de 10 mil.
                </p>
              </div>
              <div className="show-more px-4 mt-5 d-none">
                <Link
                  to="/"
                  className="h5 text-white fw-normal rounded-pill px-5 py-2 py-lg-3"
                >
                  Ver mais
                </Link>
              </div>
            </div>
            <div className="col-lg-4 photos d-flex flex-column align-items-center align-items-lg-end">
              <img
                src={minister}
                alt="minister"
                className="d-none d-lg-block"
              />
              <img src={church} alt="church" />
            </div>
          </div>
        </div>
      </AboutUs>
      <Contact id="contato">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="box px-3 py-4 p-md-5">
                <div className="row px-lg-5 align-items-center">
                  <div className="col-lg-6 py-5">
                    <div className="w-75">
                      <h2 className="h4 fw-normal">Sede Q540</h2>
                      <h3 className="h1 fw-semibold text-white">
                        Entre em contato conosco
                      </h3>
                    </div>
                    <div className="mt-5">
                      <a
                        href="mailto:contato@quadrangular540.com.br"
                        className="d-block small h5-lg"
                      >
                        <div className="d-flex align-items-center">
                          <BsFillEnvelopeFill
                            size={24}
                            color="#FFFFFF"
                            className="me-2"
                          />
                          <span>contato@quadrangular540.com.br</span>
                        </div>
                      </a>
                      <a href="tel:+551141942147" className="d-block">
                        <div className="d-flex align-items-center">
                          <BsFillTelephoneFill
                            size={24}
                            color="#FFFFFF"
                            className="me-2"
                          />
                          <span>(11) 4194-2147</span>
                        </div>
                      </a>
                      <a
                        href="https://goo.gl/maps/GPYtFdR7Gg4zZ2jb7"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="d-block"
                      >
                        <div className="d-flex align-items-center">
                          <TbMapPinFilled
                            size={24}
                            color="#FFFFFF"
                            className="me-2"
                          />
                          <span>
                            Rua Vanusa, 49 – Jardim. Audir Barueri - SP
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 mt-lg-0">
                    <div className="sub-box py-5 px-4">
                      <Form
                        onSubmit={(data) => console.log(data)}
                        className="d-flex flex-column align-items-end"
                      >
                        <label className="d-block w-100">
                          <Input name="name" placeholder="Nome" />
                        </label>
                        <label className="d-block w-100">
                          <Input name="subject" placeholder="Assunto" />
                        </label>
                        <label className="d-block w-100">
                          <Input
                            type="email"
                            name="email"
                            placeholder="Seu melhor e-mail"
                          />
                        </label>
                        <label className="d-block w-100">
                          <Textarea
                            name="message"
                            placeholder="Sua mensagem (Opcional)"
                          />
                        </label>
                        <button
                          type="submit"
                          className="border-0 bg-transparent text-white mt-5"
                        >
                          Enviar{' '}
                          <VscArrowSmallRight size={47} color="#E4E4E4" />
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Contact>
    </Container>
  );
};

export default Home;
